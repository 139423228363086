import { GenericItem } from 'utils/types';
import {
  MarketDropdownContainer,
  MarketDropDownItem,
  StyledButtonLabel,
  StyledLabel,
} from './MarketDropdownMenu.style';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { useEffect, useState } from 'react';
import usersGqls from 'pages/settings/userManagement/UserManagement.gqls';
import { useApolloClient, useMutation } from '@apollo/client';
import { AuthType, User, UserMarket, UserRole } from 'utils/types/users';
import { capitalize } from 'utils/text';
import { store } from 'app/store';
import { updateUserDetails } from 'app/slices/users';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';

export const MarketDropdownMenu = () => {
  const loggedInUser = useSelector(users).user;
  const marketInfo = loggedInUser.marketInfo;
  let defaultMarket: string;
  marketInfo.map((item) => {
    if (item.isDefault) {
      defaultMarket = item.marketFullName.toString();
    }
  });
  const client = useApolloClient();
  const [selectedMarket, setSelectedMarket] = useState<string>(defaultMarket);
  const [switchMarket] = useMutation(usersGqls.mutations.switchMarket, {
    onCompleted: () => {
      client.refetchQueries({ include: "active" });
    },
  });
  const availableMarkets: GenericItem[] = marketInfo
    .filter((item) => item.isActive)
    .map((item) => ({
      id: item.marketId,
      name: item.marketFullName as string,
    }));

  const handleMarketChange = async (item: GenericItem) => {
    setSelectedMarket(item.name);
    const updatedMarketInfo = marketInfo.map((market) => ({
      ...market,
      isDefault: market.marketId === item.id,
    }));
    const updatedUser = {
      ...loggedInUser,
      marketInfo: updatedMarketInfo,
    };
    try {
      await switchMarket({
        variables: {
          data: {
            id: loggedInUser.id,
            authType: loggedInUser.authType.toUpperCase(),
            firstName: loggedInUser.authType === AuthType.VCE ? loggedInUser.firstName : undefined,
            lastName: loggedInUser.authType === AuthType.VCE ? loggedInUser.lastName : undefined,
            company: loggedInUser.company,
            marketInfo: updatedMarketInfo.map((market) => ({
              ...market,
              role: market.role === UserRole.SysAdmin ? 'SysAdmin' : capitalize(market.role),
            })),
          },
        },
        refetchQueries: ['Users'],
      });
      showToast(MessageType.Success, `Market Switched Successfully`);
      store.dispatch(updateUserDetails(updatedUser));
    } catch (e) {
      console.error('Error in update' + e);
      showToast(MessageType.Error, `Unable to Switch Market`);
    }
  };
  useEffect(() => {
    if (availableMarkets.length === 1 && selectedMarket === defaultMarket) {
      setSelectedMarket(availableMarkets[0].name);
    }
  }, [availableMarkets, selectedMarket]);

  return (
    <MarketDropdownContainer data-automation-id="market-container">
      {availableMarkets.length > 1 ? (
        <MarketDropDownItem
          items={availableMarkets}
          onItemClick={handleMarketChange}
          position="bottom-left"
          allowClickOutsideClose={true}
        >
          <StyledButtonLabel>{selectedMarket}</StyledButtonLabel>
        </MarketDropDownItem>
      ) : (
        <StyledLabel>{selectedMarket}</StyledLabel>
      )}
    </MarketDropdownContainer>
  );
};
