import { ApolloClient, InMemoryCache, from, split, FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationCounterLink } from './operationCounter';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
const abortController = new AbortController();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Exceeded Session Timeout Threshold') {
        window.location.href = '/login';
      }
      if(message === 'Your market access has changed. Please log in again to continue..'){
        window.location.href = '/logout';
        showToast(MessageType.Error, message);
      }
      if(message === 'Your role has been updated. Please log in again to continue.'){
        window.location.href = '/logout';
        showToast(MessageType.Error, message);
      }
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const isHttps = () => window.location.protocol.includes('s');

const httpLink = createUploadLink({
  credentials: 'include',
  headers: {
    'apollo-require-preflight': 'true',
    'x-apollo-operation-name': 'true',
  },
  uri:
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? 'http://localhost:5780/graphql'
      : '/graphql',
  fetchOptions: {
    credentials: 'include',
    signal: abortController.signal,
  },
});

const batchLink = new BatchHttpLink({
  credentials: 'include',
  uri:
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? 'http://localhost:5780/graphql'
      : '/graphql',
  batchMax: 5,
  batchInterval: 100,
  fetchOptions: {
    credentials: 'include',
    signal: abortController.signal,
  },
});
const isCypress = window.navigator.userAgent?.includes('Cypress');
console.log('Is This Cypress Refference ' + isCypress);
const nonBatchOperations = [
  'CreateImages',
  'UpdateImages',
  'BulkUpdateImages',
  'Configurations',
  'ForgotPassword',
  'CreateDoeImages',
  'UpdateDoeImages',
];
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition';
  },
  split((query) => nonBatchOperations.includes(query.operationName), httpLink, isCypress ? httpLink : batchLink),
);

const merge: FieldMergeFunction = (
  existing,
  incoming,
  {
    args: {
      data: { offset = 0 },
    },
  },
) => {
  const merged = existing ? existing.items?.slice(0) : [];
  for (let i = 0; i < incoming.items?.length; ++i) {
    merged[offset + i] = incoming.items[i];
  }
  return { ...incoming, items: merged };
};

const cacheTypePolicy: FieldPolicy = {
  keyArgs: (args) => JSON.stringify(args.data.filters),
  merge,
};

const cacheTypePolicyForImages: FieldPolicy = {
  keyArgs: (args) => {
    const key = {
      filters: args.data.filters,
      imageSource: args.data.imageSource,
    };
    return JSON.stringify(key);
  },
  merge,
};

const cacheTypePolicyWithoutMerge: FieldPolicy = {
  keyArgs: (args) => JSON.stringify(args.data.filters),
};

const cacheTypePolicyWithOrder: FieldPolicy = {
  keyArgs: (args) => JSON.stringify({ ...args.data.filters, ...args.data.order }),
  merge,
};

export const client = new ApolloClient({
  link: from([errorLink, OperationCounterLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getOffers: cacheTypePolicy,
          getNonCategorizedProducts: cacheTypePolicy,
          getProductSets: cacheTypePolicyWithOrder,
          getProductSetsProductSetsPage: cacheTypePolicyWithOrder,
          getLocations: cacheTypePolicy,
          getLocationsLocationSetsPage: cacheTypePolicy,
          getLocationSets: cacheTypePolicy,
          getLocationSetsLocationSetsPage: cacheTypePolicy,
          getImages: cacheTypePolicyForImages,
          getTermsTermsNCondsPage: cacheTypePolicy,
          getDoeOffers: cacheTypePolicy,
          getAudit: {
            keyArgs: (args) => JSON.stringify({ ...args.data.filters, ...args.data.order, ...args.data.queryKey }),
            merge,
          },
          getCampaigns: cacheTypePolicyWithOrder,
          getDoeCampaigns: cacheTypePolicyWithOrder,
          getUsers: {
            keyArgs: (args) => JSON.stringify({ ...args.data.filters, ...args.data.order, ...args.data.queryKey }),
            merge,
          },
          getNotifications: cacheTypePolicyWithOrder,
          getTags: {
            keyArgs: (args) => JSON.stringify({ ...args.data.filters, ...args.data.entities, ...args.data.order }),
            merge,
          },
          getTagsTagsPage: {
            keyArgs: (args) => JSON.stringify({ ...args.data.filters, ...args.data.entities, ...args.data.order }),
            merge,
          },
          getCategories: {
            keyArgs: (args) => JSON.stringify(args.data.filters),
            merge(
              existing,
              incoming,
              {
                args: {
                  data: { offset = 0 },
                },
              },
            ) {
              if (offset > incoming.total) {
                return existing;
              }
              if (!incoming.items) {
                return incoming;
              }
              const merged = existing ? existing.items.slice(0) : [];
              for (let i = 0; i < incoming.items.length; ++i) {
                merged[offset + i] = incoming.items[i];
              }
              return { ...incoming, items: merged };
            },
          },
          getCategoriesProductSetsPage: {
            keyArgs: (args) => JSON.stringify(args.data.filters),
            merge(
              existing,
              incoming,
              {
                args: {
                  data: { offset = 0 },
                },
              },
            ) {
              if (offset > incoming.total) {
                return existing;
              }
              if (!incoming.items) {
                return incoming;
              }
              const merged = existing ? existing.items.slice(0) : [];
              for (let i = 0; i < incoming.items.length; ++i) {
                merged[offset + i] = incoming.items[i];
              }
              return { ...incoming, items: merged };
            },
          },
          getPeriods: cacheTypePolicy,
          getPeriodsSchedulePeriodsPage: cacheTypePolicy,
          getCampaignLocalSchedules: cacheTypePolicyWithoutMerge,
        },
      },
    },
    dataIdFromObject: (object) => {
      if (object.__typename === 'RestaurantGroupType' && object.type && object.id !== undefined) {
        return `${object.__typename}:${object.type}:${object.id}`;
      }
      return null;
    },
  }),
});
