import { useOperationCounter } from 'app/apollo/operationCounter';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '../backdrop/Backdrop';
import { Loading } from './NewModal.style';
import { Loader } from '../loader';
import difference from 'lodash/difference';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import { FormMode } from 'utils/types';
import { StyledButtonDropdown } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import NewTextModalTitle from '../text/textModalTitle/NewTextModalTitle';
import { UserRole } from 'utils/types/users';
import { users } from 'app/slices/users';
import { useSelector } from 'react-redux';
import { TagModalProps } from './Modal.const';
import { TagContainer, TagContent, TagModalHeader, TagTitle } from 'pages/settings/tags/components/tagForm/TagForm.style';
import { TagActionsList, TagActionsListForViewer } from 'utils/types/tags';
import NewLockIcon from 'pages/shared/newLock/NewLockIcon';
import { handleKeyDownModal } from 'utils/modal';

export const TagModal = ({
  children,
  mode,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  modalMode,
  showMoreActions = true,
  isArchive,
  contentWidth,
  onActionClicked,
  tagImpacts,
  tag,
  ...rest
}: TagModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();
  const { user } = useSelector(users);
  const userRole = user.marketInfo.find(marketInfo => marketInfo.isDefault).role;

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const headerRef = useRef(null);

  useEffect(() => {
    // Focus on the header when the modal opens
    if (headerRef.current) {
      headerRef.current.focus();
    }
  // add event listener to prevent focus from leaving the modal 
  const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, 'tag-form');
  document.addEventListener('keydown', keyDownListener);
  return () => {
    document.removeEventListener('keydown', keyDownListener)
  }
  }, [modalMode]);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <TagContainer className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <>
          <TagModalHeader>
            <TagTitle ref={headerRef} tabIndex={0}>
              {title && <NewTextModalTitle text={title}/>}
              {isLocked && <NewLockIcon />}
              {modalMode === FormMode.View && !isLocked && (
                <>
                  {showMoreActions && (
                    <StyledButtonDropdown
                      version="tag"
                      items={
                        !isLocked && mode !== FormMode.View
                          ? userRole != UserRole.Viewer
                            ? (Object.values(TagActionsList) as any[])
                            : (Object.values(TagActionsListForViewer) as any[])
                          : []
                      }                      
                      onItemClick={(item: any) => onActionClicked(item.name)}
                      position="bottom-left"
                      tagImpacts= {tagImpacts}
                      tag= {tag}
                    ></StyledButtonDropdown>
                  )}
                </>
              )}
            </TagTitle>
            {subtitle ?? null}
          </TagModalHeader>
          <TagContent contentWidth={contentWidth} data-automation-id="modal-content">
            {children}
          </TagContent>
        </>
      </TagContainer>
    </>,
    document.querySelector('#modal'),
  );
};

export default TagModal;
