import React, { useEffect, useState } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import {
  Approve,
  ButtonContained,
  ButtonContainedReject,
  ButtonContainedSave,
  ButtonContainedSaveAs,
  ButtonContainedStopAssoication,
  Save,
} from 'components/shared/button';
import ReactTooltip from 'react-tooltip';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { ApprovalStatus, FormMode } from 'utils/types';
import { CampaignType, VoucherGroup } from 'utils/types/campaigns';
import { isInArray } from 'utils/array';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import {
  archiveCampaign,
  getVoucherDetails,
  handleDeployOrApproveActionClick,
  rejectCampaign,
  revokeCampaign,
  stopCampaignAssociation,
  submitCampaignForApproval,
} from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';
import { hideTooltip } from 'utils/tooltip';
import { EntityApproveButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { offersSelection } from 'app/genericSlices/offers';
import {
  FormFooter,
  FormRow,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.style';
import {
  CampaignActions,
  CampaignExtendedProps,
  CampaignFormFooterProps,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.consts';
import { useHistory } from 'react-router-dom';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { getMarketDatetime } from 'utils/date';
import {
  shouldReDeploy,
  validateCampaignVoucher,
} from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';
import { OfferFilters, OfferSource } from 'pages/offers/offerManagement/Offers.const';
import {
  getHasRevokeButton,
  getHasStopAssociation,
  getIsApproveDisabled,
  getStoppedAssociationVoucher,
  getSubmitForApprovalButtonDisabled,
} from './CampaignFormFooter.util';
import {
  BaseTextButton,
  CancelButton,
  SaveButtons,
} from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';

const CampaignFormFooter = ({
  mode,
  campaignStatus,
  alert,
  isSubmitting,
  setIsSubmitting,
  isLocked,
  onSubmit,
  className,
  fromCalendar = false,
  dateTimeConfig,
  inProgress = false,
  offerSource = OfferSource.VCE,
  voucherState,
  isDirty,
  }: CampaignFormFooterProps) => {
  const { control, getValues, watch, getFieldState } = useFormContext();
  const { endTimezone, startTimezone } = dateTimeConfig;
  const { isValid } = useFormState();
  const [isFormValid, setIsFormValid] = useState(isValid && !isSubmitting);
  const [campaignType, campaignVoucherDistribution, offerVersion] = useWatch({
    control,
    name: ['type', 'voucherConfig.distribution', 'offerVersion'],
  });
  const history = useHistory();
  const isLocalCampaign = watch('isLocalCampaign');
  const offerStatusDisabled = offerVersion && offerVersion.status !== ApprovalStatus.Approved;
  const deployedCampaignUpdateTooltipMessage = campaignStatus === ApprovalStatus.Deployed ? 'a deployed' : 'an active';
  const activeCampaignUpdateTooltipMessage = campaignStatus === ApprovalStatus.Active ? 'an active' : 'a deployed';

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty, mode]);

  useEffect(() => {
    setIsFormValid(isValid && !isSubmitting);
  }, [isValid, isSubmitting]);

  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false);

  const handleRejectClick = (rejectionComment: any) => {
    if (!isRejectButtonClicked) {
      setIsRejectButtonClicked(true);
      onActionClicked(CampaignActions.Reject, rejectionComment);
      hideTooltip('#reject-tooltip');
      ReactTooltip.hide();
    }
  };

  const [isRevokeButtonClicked, setIsRevokeButtonClicked] = useState(false);

  const handleRevokeClick = () => {
    if (!isRevokeButtonClicked) {
      setIsRevokeButtonClicked(true);
      onActionClicked(CampaignActions.Revoke);
      hideTooltip('#revoke-tooltip');
    }
  };

  const onActionClicked = async (actionName: CampaignActions, extraData?: any) => {
    const campaign = getValues() as CampaignExtendedProps;
    store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
    switch (actionName) {
      case CampaignActions.SubmitForApproval: {
        if (campaignType === CampaignType.LoyaltyExternalVoucher) {
          const voucherData: VoucherGroup = await getVoucherDetails(
            mode,
            +offerVersion?.templateValues?.nonFoodDiscountRewards,
            voucherState,
          );
          if (validateCampaignVoucher(voucherData, campaign.schedule, dateTimeConfig)) {
            await submitCampaignForApproval(campaign, offerSource);
          }
        } else {
          await submitCampaignForApproval(campaign, offerSource);
        }
        break;
      }
      case CampaignActions.ReDeploy:
      case CampaignActions.Deploy:
      case CampaignActions.Approve: {
        if (campaign.schedule?.campaignEnd <= getMarketDatetime(endTimezone)) {
          showToast(MessageType.Error, `Campaign End time is in the past`);
          break;
        }
        setIsSubmitting(true);
        if (campaignType === CampaignType.LoyaltyExternalVoucher) {
          const voucherData: VoucherGroup = await getVoucherDetails(
            mode,
            +offerVersion?.templateValues?.nonFoodDiscountRewards,
            voucherState,
          );
          if (validateCampaignVoucher(voucherData, campaign.schedule, dateTimeConfig)) {
            await handleDeployOrApproveActionClick(campaign, actionName);
          }
        } else {
          await handleDeployOrApproveActionClick(campaign, actionName);
        }
        setIsSubmitting(false);
        break;
      }
      case CampaignActions.Reject: {
        await rejectCampaign(campaign, extraData);
        break;
      }
      case CampaignActions.Revoke: {
        await revokeCampaign(campaign);
        break;
      }
      case CampaignActions.StopAssociation: {
        await stopCampaignAssociation(campaign);
        break;
      }
      case CampaignActions.Unarchive: {
        await archiveCampaign(campaign, false, offerSource);
        break;
      }
      default:
    }
  };

  const isApproveDisabled = getIsApproveDisabled(isFormValid, campaignStatus, alert, voucherState?.voucherError);

  const hasStopAssociation = getHasStopAssociation(campaignType, campaignVoucherDistribution);

  const stoppedAssociationVoucher = getStoppedAssociationVoucher(
    campaignType,
    campaignVoucherDistribution,
    campaignStatus,
  );

  const gameError: boolean = !!getFieldState('voucherConfig.game').error;
  const isValidCampaign =
    campaignType !== CampaignType.LoyaltyExternalVoucher || (isFormValid && !voucherState.voucherError);

  const hasReDeploy =
    shouldReDeploy(getValues() as CampaignExtendedProps, startTimezone) && !gameError && isValidCampaign;

  const hasRevoke = getHasRevokeButton(campaignType, campaignVoucherDistribution, campaignStatus);

  const hasDeploy = campaignStatus === ApprovalStatus.Approved && getValues().isTriggerEvent;
  const isSubmitForApprovalDisabled = getSubmitForApprovalButtonDisabled(
    isFormValid,
    offerVersion,
    voucherState?.voucherError,
  );
  const StopAssociationButton = (
    <ButtonContainedStopAssoication onClick={() => null} data-tip data-for="stop-association-tooltip">
      Stop Association
    </ButtonContainedStopAssoication>
  );
  const UnarchiveButton = (
    <ButtonContainedSave onClick={() => onActionClicked(CampaignActions.Unarchive)}>Unarchive</ButtonContainedSave>
  );
  const SubmitForApprovalButton = (
    <ButtonContainedSaveAs
      disabled={!isFormValid || offerStatusDisabled}
      onClick={() => {
        const editedValues = getValues();
        store.dispatch(
          openModal({
            modal: Modals.GmaViewModal,
            props: {
              campaign: {
                status: campaignStatus,
                mode,
                offerSource,
                entity: editedValues,
              },
              onGoBack: () => {
                store.dispatch(
                  openModal({
                    modal: Modals.CampaignModal,
                    props: {
                      mode: mode,
                      campaign: editedValues,
                    },
                  }),
                );
              },
              onSubmit: () => onActionClicked(CampaignActions.SubmitForApproval),
              isDisabled: !isFormValid || offerStatusDisabled,
            },
          }),
        );
      }}
    >
      Continue
    </ButtonContainedSaveAs>
  );
  const ApproveButton = (
    <EntityApproveButton entity={getValues()} entityType={EntityType.Campaign}>
      <Approve
        disabled={isApproveDisabled}
        onClick={() => {
          const editedValues = getValues();
          store.dispatch(
            openModal({
              modal: Modals.GmaViewModal,
              props: {
                campaign: {
                  status: campaignStatus,
                  mode,
                  offerSource,
                  entity: editedValues,
                  rejectTooltip: RejectTooltip,
                },
                onGoBack: () => {
                  store.dispatch(
                    openModal({
                      modal: Modals.CampaignModal,
                      props: {
                        mode: mode,
                        campaign: editedValues,
                      },
                    }),
                  );
                },
                onSubmit: () => onActionClicked(CampaignActions.Approve),
                isDisabled: isApproveDisabled,
              },
            }),
          );
        }}
      >
        Continue to Approve
      </Approve>
    </EntityApproveButton>
  );
  const DeployButton = (
    <EntityApproveButton entity={getValues()} entityType={EntityType.Campaign}>
      <ButtonContainedSave
        disabled={!isFormValid}
        onClick={() => {
          const editedValues = getValues();
          store.dispatch(
            openModal({
              modal: Modals.GmaViewModal,
              props: {
                campaign: {
                  status: campaignStatus,
                  mode,
                  offerSource,
                  entity: editedValues,
                  rejectTooltip: RejectTooltip,
                },
                onGoBack: () => {
                  store.dispatch(
                    openModal({
                      modal: Modals.CampaignModal,
                      props: {
                        mode: mode,
                        campaign: editedValues,
                      },
                    }),
                  );
                },
                onSubmit: () => onActionClicked(CampaignActions.Deploy),
                isDisabled: !isFormValid,
              },
            }),
          );
        }}
      >
        Continue to Deploy
      </ButtonContainedSave>
    </EntityApproveButton>
  );
  const RejectButton = (
    <ButtonContainedReject onClick={() => null} data-tip data-for="reject-tooltip">
      Reject
    </ButtonContainedReject>
  );
  const RevokeButton = (
    <ButtonContainedSave
      data-for={stoppedAssociationVoucher ? 'show-stop-association-msg' : 'revoke-tooltip'}
      data-tip
      onClick={() => null}
    >
      Revoke
    </ButtonContainedSave>
  );
  const PreviewButton = (
    <BaseTextButton
      onClick={() => {
        const editedValues = getValues();
        store.dispatch(
          openModal({
            modal: Modals.GmaViewModal,
            props: {
              campaign: {
                status: campaignStatus,
                mode,
                offerSource,
                entity: editedValues,
                hasRevoke,
                hasStopAssociation,
                stoppedAssociationVoucher,
                revokeTooltip: RevokeTooltip,
                stoppedAssociationTooltip: StoppedAssociationTooltip,
                stopAssociationTooltip: StopAssociationTooltip,
              },
              onGoBack: () => {
                store.dispatch(
                  openModal({
                    modal: Modals.CampaignModal,
                    props: {
                      mode: mode,
                      campaign: editedValues,
                    },
                  }),
                );
              },
            },
          }),
        );
      }}
    >
      Preview
    </BaseTextButton>
  );

  const DeployedButtons = (
    <SaveButtons>
      <RoleGuard
        roles={
          offerSource === OfferSource.VCE
            ? [UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]
            : [UserRole.Admin, UserRole.SysAdmin]
        }
      >
        {PreviewButton}
        {hasRevoke && RevokeButton}
      </RoleGuard>
    </SaveButtons>
  );

  const ActiveButtons = (
    <>
      <RoleGuard
        roles={
          offerSource === OfferSource.VCE
            ? [UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]
            : [UserRole.Admin, UserRole.SysAdmin]
        }
      >
        {PreviewButton}
        {hasStopAssociation && StopAssociationButton}
        {hasRevoke && RevokeButton}
      </RoleGuard>
    </>
  );

  const DraftOrRejectedButtons = (
    <>
      {offerSource === OfferSource.VCE && (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {!isLocalCampaign && SubmitForApprovalButton}
          {isLocalCampaign && PreviewButton}
        </RoleGuard>
      )}
    </>
  );

  const ApprovedButtons = (
    <>
      {offerSource === OfferSource.VCE ? (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {!isLocalCampaign && RejectButton}
          {hasDeploy && DeployButton}
        </RoleGuard>
      ) : (
        <RoleGuard roles={[UserRole.Admin, UserRole.SysAdmin]}>{!isLocalCampaign && RejectButton}</RoleGuard>
      )}
    </>
  );

  const StopAssociationButtons = (
    <>
      {offerSource === OfferSource.VCE && (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {PreviewButton}
          {RevokeButton}
        </RoleGuard>
      )}
    </>
  );

  const DeploymentFailedButtons = (
    <>
      {offerSource === OfferSource.VCE && hasReDeploy && (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          <ButtonContainedSave onClick={() => onActionClicked(CampaignActions.ReDeploy)}>Re-Deploy</ButtonContainedSave>
        </RoleGuard>
      )}
    </>
  );

  const viewModeButtonsByStatus: Partial<Record<ApprovalStatus, JSX.Element>> = {
    [ApprovalStatus.Draft]: DraftOrRejectedButtons,
    [ApprovalStatus.Rejected]: DraftOrRejectedButtons,
    [ApprovalStatus.Deployed]: DeployedButtons,
    [ApprovalStatus.DeploymentPending]: DeployedButtons,
    [ApprovalStatus.Active]: ActiveButtons,
    [ApprovalStatus.AssociationStopped]: StopAssociationButtons,
    [ApprovalStatus.DeploymentFailed]: DeploymentFailedButtons,
    [ApprovalStatus.PendingApproval]: (
      <FormRow>
        {ApprovedButtons}
        {!isLocalCampaign && ApproveButton}
        {isLocalCampaign && PreviewButton}
      </FormRow>
    ),
    [ApprovalStatus.Approved]: ApprovedButtons,
    [ApprovalStatus.Archived]: UnarchiveButton,
  };

  const revokeTooltipContent =
    campaignType === CampaignType.Voucher
      ? 'Please consult with legal prior to revoking voucher campaign'
      : 'Are you sure you want to revoke this campaign?';

  const renderViewModeButtons = (): JSX.Element => {
    if (fromCalendar) {
      return (
        <ButtonContained
          onClick={() => {
            history.push(`/campaigns?id=${getValues().externalId}`);
            store.dispatch(closeModal());
          }}
        >
          Go to Campaign Management
        </ButtonContained>
      );
    }
    return viewModeButtonsByStatus[campaignStatus];
  };
  const getButtonsByModeAndStatus = (): JSX.Element => {
    switch (mode) {
      case FormMode.New:
      case FormMode.Duplicate:
        return (
          <>
            <ButtonContainedSave onClick={onSubmit(true)} disabled={!isFormValid || !isDirty}>
              Save as Draft
            </ButtonContainedSave>
            {!isLocalCampaign && (
              <ButtonContainedSaveAs
                disabled={isSubmitForApprovalDisabled || !isDirty}
                onClick={() => {
                  const editedValues = getValues();
                  store.dispatch(
                    openModal({
                      modal: Modals.GmaViewModal,
                      props: {
                        campaign: {
                          status: campaignStatus,
                          mode,
                          offerSource,
                          entity: editedValues,
                        },
                        onGoBack: () => {
                          store.dispatch(
                            openModal({
                              modal: Modals.CampaignModal,
                              props: {
                                mode: mode,
                                campaign: editedValues,
                              },
                            }),
                          );
                        },
                        onSubmit: onSubmit(false),
                        isDisabled: isSubmitForApprovalDisabled || !isDirty,
                      },
                    }),
                  );
                }}
              >
                Save and Continue
              </ButtonContainedSaveAs>
            )}
          </>
        );
      case FormMode.Edit:
        if (offerSource === OfferSource.VCE) {
          if (
            isInArray(
              [ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped],
              campaignStatus,
            )
          ) {
            return (
              <BaseTextButton
                disabled={!isFormValid || offerStatusDisabled || !isDirty}
                onClick={() => {
                  const editedValues = getValues();
                  store.dispatch(
                    openModal({
                      modal: Modals.GmaViewModal,
                      props: {
                        campaign: {
                          status: campaignStatus,
                          mode,
                          offerSource,
                          entity: editedValues,
                          saveDeployedTooltip: SaveDeployedTooltip,
                        },
                        onGoBack: () => {
                          store.dispatch(
                            openModal({
                              modal: Modals.CampaignModal,
                              props: {
                                mode: mode,
                                campaign: editedValues,
                              },
                            }),
                          );
                        },
                        isDisabled: !isFormValid || offerStatusDisabled || !isDirty,
                      },
                    }),
                  );
                }}
              >
                Preview
              </BaseTextButton>
            );
          }
          if (isLocalCampaign) {
            return (
              <Save onClick={onSubmit(campaignStatus === ApprovalStatus.Draft)} disabled={!isFormValid || !isDirty}>
                Save
              </Save>
            );
          }
          return (
            <>
              <ButtonContainedSave onClick={onSubmit(true)} disabled={!isFormValid || !isDirty}>
                Save as Draft
              </ButtonContainedSave>
              <ButtonContainedSaveAs
                disabled={isSubmitForApprovalDisabled || !isDirty}
                onClick={() => {
                  const editedValues = getValues();
                  store.dispatch(
                    openModal({
                      modal: Modals.GmaViewModal,
                      props: {
                        campaign: {
                          status: campaignStatus,
                          mode,
                          offerSource,
                          entity: editedValues,
                        },
                        onGoBack: () => {
                          store.dispatch(
                            openModal({
                              modal: Modals.CampaignModal,
                              props: {
                                mode: mode,
                                campaign: editedValues,
                              },
                            }),
                          );
                        },
                        onSubmit: onSubmit(false),
                        isDisabled: isSubmitForApprovalDisabled || !isDirty,
                      },
                    }),
                  );
                }}
              >
                Save and Continue
              </ButtonContainedSaveAs>
            </>
          );
        } else {
          return (
            <BaseTextButton
              disabled={isSubmitForApprovalDisabled || !isDirty}
              onClick={() => {
                const editedValues = getValues();
                store.dispatch(
                  openModal({
                    modal: Modals.GmaViewModal,
                    props: {
                      campaign: {
                        status: campaignStatus,
                        mode,
                        offerSource,
                        entity: editedValues,
                        saveDeployedTooltip: SaveDeployedTooltip,
                      },
                      onGoBack: () => {
                        store.dispatch(
                          openModal({
                            modal: Modals.CampaignModal,
                            props: {
                              mode: mode,
                              campaign: editedValues,
                            },
                          }),
                        );
                      },
                      isDisabled: isSubmitForApprovalDisabled || !isDirty,
                    },
                  }),
                );
              }}
            >
              Preview
            </BaseTextButton>
          );
        }
      case FormMode.View:
        return !inProgress && renderViewModeButtons();
      default:
        return null;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
      store.dispatch(closeModal());
    }
  };

  const RevokeTooltip = (
    <Tooltip
      id="revoke-tooltip"
      content={
        campaignType === CampaignType.Voucher
          ? 'Please consult with legal prior to revoking voucher campaign'
          : 'Are you sure you want to revoke this campaign?'
      }
      approveMsg="Yes, Revoke"
      onDisapproveClick={() => {
        hideTooltip('#revoke-tooltip');
        setIsRevokeButtonClicked(false);
      }}
      onApproveClick={handleRevokeClick}
    />
  );

  const StoppedAssociationTooltip = (
    <Tooltip
      id="show-stop-association-msg"
      content={'Unable to revoke the digital voucher as the association has already been stopped.'}
      approveMsg="Close"
      onApproveClick={() => {
        hideTooltip('#show-stop-association-msg');
      }}
    />
  );

  const StopAssociationTooltip = (
    <Tooltip
      id="stop-association-tooltip"
      content="Are you sure you want to stop association of this campaign?"
      onDisapproveClick={() => {
        hideTooltip('#stop-association-tooltip');
      }}
      approveMsg="Yes, Stop Association"
      onApproveClick={() => onActionClicked(CampaignActions.StopAssociation)}
    />
  );

  const RejectTooltip = (
    <Tooltip
      id="reject-tooltip"
      content="Are you sure you want to reject?"
      onDisapproveClick={() => {
        hideTooltip('#reject-tooltip');
        ReactTooltip.hide();
        setIsRejectButtonClicked(false);
      }}
      eventOff={null}
      approveMsg="Yes, Reject"
      isWithResponse
      responsePlaceholder="Enter reject reason"
      onApproveWithResponseClick={(rejectionComment) => handleRejectClick(rejectionComment)}
    />
  );

  const SaveDeployedTooltip = (
    <Tooltip
      id="deployed-active-tooltip"
      content={
        getValues('type') === CampaignType.Voucher
          ? `You are about to update ${
              campaignStatus === ApprovalStatus.Deployed ? 'a deployed' : 'an active'
            } voucher campaign. Please consult the legal department before proceeding.`
          : `Are you sure you want to update ${
              campaignStatus === ApprovalStatus.Active ? 'an active' : 'a deployed'
            } campaign?`
      }
      onDisapproveClick={() => {
        hideTooltip('#deployed-active-tooltip');
      }}
      approveMsg="OK, Save"
      onApproveClick={onSubmit(false)}
    />
  );

  return (
    <FormFooter className={className}>
      <CancelButton
        noStopPropagation
        data-for="cancel-tooltip"
        {...(isDirty || mode === FormMode.Duplicate
          ? { 'data-tip': true, onClick: () => null }
          : { onClick: () => store.dispatch(closeModal()) })}
        onKeyDown={handleKeyDown}
      >
        Cancel
      </CancelButton>
      {!isLocked && getButtonsByModeAndStatus()}
      <OfferToolTip
        id="cancel-tooltip"
        content="Are you sure you want to cancel?"
        onDisapproveClick={() => {
          hideTooltip('#cancel-tooltip');
        }}
        onApproveClick={() => {
          store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
          store.dispatch(closeModal());
        }}
      />
      {RevokeTooltip}
      {StoppedAssociationTooltip}
      {StopAssociationTooltip}
      {RejectTooltip}
      {SaveDeployedTooltip}
    </FormFooter>
  );
};

export default CampaignFormFooter;
