import React from 'react';
import {
  ApprovalStatus,
  FormMode,
  GenericItem,
  GenericSet,
  isViewMode,
  ProductSetType,
  SetOfEntity,
} from 'utils/types';
import { Product, ProductSet } from 'utils/types/products';
import { searchProduct, searchSet } from 'utils/product';
import {
  createProductSet,
  deleteProductSet,
  getCampaignsImpactsByProductSetId,
  getAvailableProductCount,
  getOffersImpactsByProductSetId,
  updateProductSet,
} from 'utils/api/productSets';
import SetItemsSelectionForm from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import {
  AccordionContentType,
  SetItemsSelectionFormState,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { StyledProductSetModal } from 'pages/settings/productSets/components/productSetModal/ProductSetModal.style';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { Impact } from 'components/impact/impactItem/ImpactItem.consts';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { handleOfferImpactChange } from 'utils/offer';
import { handleCampaignImpactChange } from 'utils/campaign';
import { OfferProps } from 'pages/offers/offerManagement/Offers.const';
import { AvailableAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/AvailableSetItemsListPanel.consts';
import { SelectedAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import { SelectedDisplayMode } from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem.consts';
import { ProductSetsFilters } from 'pages/settings/productSets/ProductSets.consts';
import ProductItem from 'pages/settings/productSets/components/productItem/ProductItem';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { isInArray } from 'utils/array';
import ProductSetTitle from 'pages/settings/productSets/components/productSetTitle/ProductSetTitle';
import { getProductSetsFilters } from 'app/slices/products';
import { ProductsSetModalProps } from './ProductSetModal.consts';
import productSetsGqls from '../../ProductSets.gqls';
import { UsageEntityType } from 'app/genericSlices/usage';
import { campaignUsage as campaignUsageSlice, offerUsage as offerUsageSlice } from 'app/genericSlices/usage';

const ProductSetModal = ({
  mode = FormMode.New,
  productSet,
  onCancel,
  onSave,
  isOptional,
  setType,
  familyGroup,
  isNonProduct,
  isDoe= false,
}: ProductsSetModalProps) => {
  const { New, Duplicate, Edit, View, Select, SelectionView, QuickView } = FormMode;
  const { config } = useSelector(marketConfig);
  const { dateFormat } = config;
  const offerImpactsBySetId = (limit?: number) => {
    return getOffersImpactsByProductSetId(productSet.externalId, limit);
  };

  const campaignImpactsBySetId = (limit?: number) => {
    return getCampaignsImpactsByProductSetId(productSet.externalId, limit);
  };

  const onDelete = async () => {
    try {
      await deleteProductSet(Number(productSet.id));
      store.dispatch(closeModal());
      showToast(MessageType.Success, `Custom Product Set deleted successfully`);
    } catch (e) {
      showToast(MessageType.Error, `Failed to delete product set`);
    }
  };

  const onUpdate = async (state: SetItemsSelectionFormState) => {
    try {
      await updateProductSet(state.id, state.name, state.description ?? '', Object.values(state.selectedItemsById));
      showToast(MessageType.Success, `Custom Product Set updated successfully`);
      store.dispatch(closeModal());
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to update product set`.concat(
          e.message.includes('duplication item') && state.name ? ` - ${state.name} already exists` : '',
        ),
      );
    }
  };

  const openProductSetImpactModal = (
    title: string,
    headerComponentType: HeaderComponentType,
    campaignImpacts: Impact[],
    offerImpacts?: Impact[],
    onSubmit?: (set: ProductSet) => void,
    submitButtonString?: string,
  ) => {
    store.dispatch(
      openModal({
        modal: Modals.ImpactModal,
        props: {
          title,
          headerComponentType,
          campaignImpacts,
          offerImpacts,
          entityType: 'Set',
          entityId: productSet.externalId,
          onCancel: () => store.dispatch(closeModal()),
          onSubmit,
          submitButtonString,
        },
      }),
    );
  };

  const openProductSetUsageModal = (
    title: string,
    headerComponentType: HeaderComponentType,
    haveCampaign = false,
    haveOffer = false,
    onSubmit?: (set: ProductSet) => void,
    submitButtonString?: string,
  ) => {
    store.dispatch(
      openModal({
        modal: Modals.OldUiUsageModal,
        props: {
          title,
          headerComponentType,
          haveCampaign,
          haveOffer,
          entityType: UsageEntityType.ProductSet,
          entityId: productSet.externalId,
          onCancel: () => {
            store.dispatch(campaignUsageSlice.actions.resetFilters());
            store.dispatch(offerUsageSlice.actions.resetFilters());
            store.dispatch(closeModal());
          },
          onSubmit,
          submitButtonString,
        },
      }),
    );
  };

  const onProductSetViewImpact = async (
    isDelete: boolean,
    campaignsImpacts: Impact[],
    offersImpacts: Impact[],
    canSave = false,
    onSubmit?: (set: ProductSet) => void,
  ) => {
    if (isDelete && canSave) {
      // Product Set can be deleted when it's not the only product set in offer or when offer is archived
      openProductSetImpactModal(
        'Delete Notification',
        HeaderComponentType.ProductSetDeleteSubtitleType,
        campaignsImpacts,
        offersImpacts,
        () => onDelete(),
        'Yes, Delete',
      );
    } else if (isDelete && !canSave) {
      openProductSetImpactModal(
        'Delete Notification',
        HeaderComponentType.ProductSetDeleteNotificationType,
        campaignsImpacts,
        offersImpacts,
      );
    } else if (!isDelete && canSave) {
      openProductSetUsageModal(
        'Save Notification',
        HeaderComponentType.ProductSetSaveNotificationType,
        campaignsImpacts.length > 0,
        offersImpacts.length > 0,
        onSubmit,
        'Yes, Save',
      );
    } else if (!isDelete && (campaignsImpacts.length || offersImpacts.length)) {
      openProductSetUsageModal(
        'Products Set Usage',
        HeaderComponentType.ProductSetUsageType,
        campaignsImpacts.length > 0,
        offersImpacts.length > 0,
      );
    } else {
      showToast(MessageType.Info, `The custom product set is not being used in campaigns or offers`);
    }
  };

  const handleSave = async (state: SetItemsSelectionFormState) => {
    try {
      switch (mode) {
        case New:
        case Duplicate:
          await createProductSet(state.name, Object.values(state.selectedItemsById));
          showToast(MessageType.Success, `Custom Product Set added successfully`);
          store.dispatch(closeModal());
          break;
        case Edit: {
          const [offerImpacts, campaignImpacts] = await Promise.all([
            offerImpactsBySetId(25),
            campaignImpactsBySetId(25),
          ]);
          if (offerImpacts.length || campaignImpacts.length) {
            const offerImpactProps = handleOfferImpactChange(offerImpacts);
            const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
            await onProductSetViewImpact(false, campaignsImpactProps, offerImpactProps, true, () => onUpdate(state));
          } else {
            await onUpdate(state);
          }
          break;
        }
        default:
          break;
      }
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to ${mode === FormMode.Edit ? 'update' : 'create'} product set`.concat(
          e.message.includes('duplication item') && state.name ? ` - ${state.name} already exists` : '',
        ),
      );
    }
  };

  const atLeastOneOfferIsNotArchive = (offers: OfferProps[]) =>
    !offers.some((offer: OfferProps) => offer.versions[0].status !== ApprovalStatus.Archived);

  const hasSingleProductSetWithoutProducts = (productSet: any) =>
    productSet.productSets.length === 1 && !productSet?.products?.length;

  const theTemplateValuesHasAtLeastOneProductSet = (offers: OfferProps[]) =>
    !offers.some((offer: OfferProps) => {
      const { templateValues } = offer.versions[0];
      switch (offer.templateType) {
        case '1':
        case '11':
          return (
            hasSingleProductSetWithoutProducts(templateValues.buyProducts) ||
            hasSingleProductSetWithoutProducts(templateValues.getProducts)
          );
        case '2':
          return hasSingleProductSetWithoutProducts(templateValues.products);
        case '3':
          return hasSingleProductSetWithoutProducts(templateValues.buyProducts);
        case '4':
          return hasSingleProductSetWithoutProducts(templateValues.substitutes);
        case '5':
          return (
            (templateValues.excludeCodes &&
              hasSingleProductSetWithoutProducts(templateValues.excludeCodes) ) ||
            (templateValues.includeNonFoodProductCodes &&
              hasSingleProductSetWithoutProducts(templateValues.includeNonFoodProductCodes)) ||
            (templateValues.conditionProducts &&
              hasSingleProductSetWithoutProducts(templateValues.conditionProducts))
          );
        case '7':
          return (
            hasSingleProductSetWithoutProducts(templateValues.buyProductsA) || hasSingleProductSetWithoutProducts(templateValues.buyProductsB) || hasSingleProductSetWithoutProducts(templateValues.getProducts)
          );
        case '8':
          return ( hasSingleProductSetWithoutProducts(templateValues.buyProducts)
            || hasSingleProductSetWithoutProducts(templateValues.getProductsA)
            || hasSingleProductSetWithoutProducts(templateValues.getProductsB)
          );
        case '10':
          return (
            templateValues.products ||
            (templateValues.excludeCodes &&
              templateValues.excludeCodes.productSets.length === 1 &&
              !templateValues.excludeCodes.products.length) ||
            (templateValues.includeNonFoodProductCodes &&
              templateValues.includeNonFoodProductCodes.productSets.length === 1 &&
              !templateValues?.includeNonFoodProductCodes?.products?.length) ||
            (templateValues.conditionProducts &&
              templateValues.conditionProducts?.productSets.length === 1 &&
              !templateValues.conditionProducts?.products?.length)
          );
        case '13':
          return templateValues.offerTemplates.some(
            (offerTemplate: any) =>
              offerTemplate.buyProducts.productSets.length === 1 && !templateValues?.buyProducts?.products?.length,
          );
        default:
          return false;
      }
    });

  const canDeleteByTemplateTypeOrArchiveStatus = (offers: OfferProps[]) => {
    return atLeastOneOfferIsNotArchive(offers) || theTemplateValuesHasAtLeastOneProductSet(offers);
  };

  const handleDelete = async () => {
    const [offerImpacts, campaignImpacts] = await Promise.all([offerImpactsBySetId(), campaignImpactsBySetId()]);
    if (offerImpacts.length || campaignImpacts.length) {
      const offerImpactProps = handleOfferImpactChange(offerImpacts);
      const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
      await onProductSetViewImpact(
        true,
        campaignsImpactProps,
        offerImpactProps,
        canDeleteByTemplateTypeOrArchiveStatus(offerImpacts),
      );
    } else {
      await onDelete();
    }
  };

  const onEdit = () => {
    store.dispatch(
      openModal({
        modal: Modals.ProductSetModal,
        props: { mode: Edit, productSet, ...(isNonProduct && { isNonProduct }) },
      }),
    );
  };

  const onDuplicate = () => {
    store.dispatch(
      openModal({
        modal: Modals.ProductSetModal,
        props: { mode: Duplicate, productSet },
      }),
    );
  };

  const onViewUsage = async () => {
    await onProductSetViewImpact(
      false,
      handleCampaignImpactChange(await campaignImpactsBySetId(25)),
      handleOfferImpactChange(await offerImpactsBySetId(25)),
      false,
    );
  };

  const getAvailableProductsCountByFilters = async (searchValue: string, selectedItemsIds: number[]) => {
    const filters = {
      [ProductSetsFilters.SearchQuery]: searchValue,
      [ProductSetsFilters.FamilyGroup]: familyGroup,
      [ProductSetsFilters.WithoutProductsIds]: selectedItemsIds,
    };
    const result = await getAvailableProductCount(filters,isDoe);
    return result.total;
  };

  const getAvailableNonFoodProductsCountByFilters = async (searchValue: string, selectedItemsIds: number[]) => {
    const filters = getProductSetsFilters({
      [ProductSetsFilters.SearchQuery]: searchValue,
      [ProductSetsFilters.SetType]: setType,
    });
    Object.assign(filters, {
      [ProductSetsFilters.WithoutProductsIds]: selectedItemsIds,
    });
    const result = await getAvailableProductCount(filters,isDoe);
    return result.total;
  };

  const titleByMode: Record<FormMode, string> = {
    [New]: 'Add Product Set',
    [Select]: 'Product Selection',
    [Edit]: `Edit ${productSet?.name} Set`,
    [View]: productSet?.name ? `View ${productSet?.name} (${productSet?.products?.length} Products)` : 'View Products',
    [Duplicate]: `Duplicate ${productSet?.name} Set`,
    [SelectionView]: productSet?.name
      ? `View ${productSet?.name} (${productSet?.products?.length} Products)`
      : 'View Products',
    [QuickView]: '',
  };

  const availableProductsSectionProps: AvailableAccordionProps[] = [
    {
      name: 'custom-sets',
      headline: 'Sets',
      gqlQuery: productSetsGqls.queries.getAllProductSets,
      gqlName: 'getProductSets',
      getFilters: (searchValue: string) => {
        return getProductSetsFilters({
          [ProductSetsFilters.SearchQuery]: searchValue,
          familyGroup,
          setType,
        });
      },
      selectedSetsDisplayMode: mode === FormMode.Select ? SelectedDisplayMode.Hide : SelectedDisplayMode.Disable,
      selectedItemsDisplayMode: SelectedDisplayMode.Disable,
      // sortOptions: [Az, Za, IdAsc, IdDesc],
    },
  ];

  if (setType !== ProductSetType.NonFoodProduct) {
    availableProductsSectionProps.push({
      name: 'products',
      headline: 'Products',
      gqlQuery: productSetsGqls.queries.getCategories,
      gqlName: ['getCategories', 'getNonCategorizedProducts'],
      getFilters: (searchValue: string) => ({ [ProductSetsFilters.SearchQuery]: searchValue, familyGroup }),
      selectedItemsDisplayMode: SelectedDisplayMode.Disable,
      selectedSetsDisplayMode: mode === FormMode.Select ? SelectedDisplayMode.Hide : SelectedDisplayMode.Disable,
      // sortOptions: [Az, Za, PluAsc, PluDesc],
    });
  }

  const selectedProductsSectionProps: SelectedAccordionProps[] = [
    {
      name: 'selected-products',
      headline: mode !== View ? 'Products' : '',
      contentType: AccordionContentType.Items,
      searchItem: searchProduct as (item: GenericItem, searchQuery: string) => boolean,
      searchItemSet: searchSet as (set: GenericSet, searchQuery: string) => boolean,
    },
  ];

  if (isInArray([FormMode.Select, FormMode.SelectionView], mode)) {
    selectedProductsSectionProps.unshift({
      name: 'selected-product-sets',
      headline: 'Sets',
      contentType: AccordionContentType.Set,
      searchItem: searchProduct as (item: GenericItem, searchQuery: string) => boolean,
      searchItemSet: searchSet as (set: GenericSet, searchQuery: string) => boolean,
    });
  }
  const viewMode = isViewMode(mode);
  return (
    <StyledProductSetModal
      isViewMode={viewMode}
      title={titleByMode[mode]}
      ignoreOperations={['GetCategories', 'ProductSets']}
    >
      <SetItemsSelectionForm
        itemSet={productSet}
        setOf={SetOfEntity.Products}
        formMode={mode}
        searchPlaceholder="Search"
        onSave={onSave ?? handleSave}
        onClose={onCancel}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
        onViewUsage={onViewUsage}
        onDelete={handleDelete}
        itemSetTitleFormatter={ProductSetTitle}
        itemFormatter={(product: Product) => ProductItem(product, dateFormat)}
        fetchTotalAvailableItems={
          setType === ProductSetType.NonFoodProduct
            ? getAvailableNonFoodProductsCountByFilters
            : getAvailableProductsCountByFilters
        }
        availableAccordionsProps={availableProductsSectionProps}
        selectedAccordionsProps={selectedProductsSectionProps}
        setType={setType}
        {...(isNonProduct && { isNonProduct })}
        isOptional={isOptional}
        isDoe={isDoe}
        modalMode={mode}
      />
    </StyledProductSetModal>
  );
};
export default ProductSetModal;
